<template>
  <div>
    <!-- <van-loading type="spinner" v-if="loading" /> -->
      <van-nav-bar
        :title='$t("settle.settleOrder")'
        left-text=""
        left-arrow
        :fixed="true"
        @click-left="handleBackClick"
      />
      <div class="wrapper">
        <van-list
          v-if="settleAccountList.length !== 0"        
          v-model="isSettleAccountLoading"              
          :finished="isSettleAccontFinished"
          :finished-text='$t("mine.nomore")'
          :immediate-check="false"
          :loading-text='$t("settle.loading")'
          @load="handleSettleAccountLoad"
          class="list"
          offset="40"
          >
          <template v-for="item in settleAccountList">
            <SettleCard :settle-item="item"/>
          </template>
        </van-list>
        <template v-else> 
          <MyEmpty :is-small-size="false"/>
        </template>
        <div v-show="settleAccountList.length === 0 && isSettleAccountLoading" ><van-loading type="spinner"/></div>
      </div>
  </div>
</template>

<script>
import SettleCard from '../components/SettleCard.vue';
import MyEmpty from '../components/MyEmpty.vue';
import loadMoreFromBottom from "../mixin/loadMoreFromBottom";

export default {
  mixins: [loadMoreFromBottom],
  data() {
    return {
    }
  },
  components: { SettleCard, MyEmpty },
  methods: {
    handleBackClick() {
      this.$router.back(-1);
    }
  }
}
</script>

<style lang="less" scoped>
.wrapper {
  margin-top: 60px;
  padding: 0 24px;
}

/deep/ .van-list__loading {
  position: relative;
}

/deep/ .van-list__loading .van-loading {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
}

</style>