import { getSettleAccountList} from "@network/api";

export default {
  data() {
    return {
      // 结算订单
      settleAccountTotal: 0,
      settleAccountParams: {
        page: 1,
        pageSize: 10,
      },
      isSettleAccountLoading: false,
      isSettleAccontFinished: false,
      settleAccountList: [],
    }
  },
  mounted() {
    this.fetchSettleAccountData()
  },
  methods: {
    handleSettleAccountLoad() {
      if(this.settleAccountList.length === this.settleAccountTotal) {
        this.isSettleAccontFinished = true
        return
      }
      this.settleAccountParams.page++
      this.fetchSettleAccountData(this.settleAccountParams)
    },
    fetchSettleAccountData(params = { page: 1, pageSize: 10 }) {
      this.isSettleAccountLoading = true
      getSettleAccountList(params)
        .then((res) => {
          this.isSettleAccountLoading = false
          if(res.code === "000000") {
            const { records = [], total = 0 } = res.data
            this.settleAccountList.push(...records)
            this.settleAccountTotal = total
            if(total === records.length) {
              this.isSettleAccontFinished = true
            }
          } else {
            this.$toast(res.message || this.$t("message.normalError"))
          }
        }).catch((err) => {
          this.isSettleAccountLoading = false
          console.log(err)
        })
    },
  }
}